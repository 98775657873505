.flex {
  display: flex;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.flexDirectionColumn {
  flex-direction: column;
}

.flexDirectionRow {
  flex-direction: row;
}

.alignItemsCenter {
  align-items: center;
}

.twentyFourPadding {
  padding: 24px;
}

.paddingSixteen {
  padding: 16px;
}
